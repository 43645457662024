@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* ADDED FOR DARK MODE */
:root {
  --background-color: #f3f4f6;
  --text-color: #1f2937;
  --header-bg: #ffffff;
  --sidebar-bg: #1f2937;
  --card-bg: #ffffff;
  --hover-bg: #e5e7eb;
  --border-color: #d1d5db;
  --button-bg: #3b82f6;
  --button-text: #ffffff;
  --button-hover: #2563eb;
  --input-bg: #ffffff;
  --input-border: #d1d5db;
  --input-text: #1f2937;
  --table-header-bg: #f9fafb;
  --table-border: #e5e7eb;
  --chart-bg: #ffffff;
}

html.dark {
  --background-color: #111827;
  --text-color: #f3f4f6;
  --header-bg: #1f2937;
  --sidebar-bg: #111827;
  --card-bg: #1f2937;
  --hover-bg: #374151;
  --border-color: #4b5563;
  --button-bg: #3b82f6;
  --button-text: #ffffff;
  --button-hover: #2563eb;
  --input-bg: #374151;
  --input-border: #4b5563;
  --input-text: #f3f4f6;
  --table-header-bg: #1f2937;
  --table-border: #4b5563;
  --chart-bg: #1f2937;
}

body {
  background-color: var(--background-color);
  color: var(--text-color);
}

/* Update all other component styles to use CSS variables */
.bg-white, .bg-gray-100, .bg-gray-200 {
  background-color: var(--card-bg);
}

.text-gray-600, .text-gray-700, .text-gray-800 {
  color: var(--text-color);
}


.dark {
  --background-color: #111827;
  --text-color: #f3f4f6;
  --header-bg: #1f2937;
  --sidebar-bg: #111827;
  --card-bg: #1f2937;
  --hover-bg: #374151;
  --border-color: #4b5563;
  --button-bg: #3b82f6;
  --button-text: #ffffff;
  --button-hover: #2563eb;
  --input-bg: #374151;
  --input-border: #4b5563;
  --input-text: #f3f4f6;
  --table-header-bg: #1f2937;
  --table-border: #4b5563;
  --chart-bg: #1f2937;
}

body {
  background-color: var(--background-color);
  color: var(--text-color);
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Header styles */
header {
  background-color: var(--header-bg);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
}

/* Sidebar styles */
aside {
  background-color: var(--sidebar-bg);
  color: #ffffff;
}

/* Card styles */
.card, .bg-white {
  background-color: var(--card-bg);
  border-color: var(--border-color);
}

/* Button styles */
button, .btn {
  background-color: var(--button-bg);
  color: var(--button-text);
  transition: background-color 0.2s;
}

button:hover, .btn:hover {
  background-color: var(--button-hover);
}

/* Input styles */
input, select, textarea {
  background-color: var(--input-bg);
  border-color: var(--input-border);
  color: var(--input-text);
}

/* Table styles */
table {
  border-color: var(--table-border);
}

th {
  background-color: var(--table-header-bg);
}

/* Chart styles */
.chart-container {
  background-color: var(--chart-bg);
}

/* Text colors */
.text-gray-600 {
  color: var(--text-color);
}

.text-gray-800 {
  color: var(--text-color);
}

/* Hover effects */
.hover\:bg-gray-200:hover {
  background-color: var(--hover-bg);
}

/* Specific component styles */
.breadcrumbs {
  background-color: var(--card-bg);
  border-color: var(--border-color);
}

.set-details, .set-statistics {
  background-color: var(--card-bg);
  border-color: var(--border-color);
}

.card-list .card {
  background-color: var(--card-bg);
  transition: transform 0.2s, box-shadow 0.2s;
}

.card-list .card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

/* Rarity legend styles */
.rarity-legend {
  display: inline-flex;
  align-items: center;
  padding: 0.25rem 0.5rem;
  border-radius: 9999px;
  font-size: 0.75rem;
  font-weight: 600;
}

/* Type icon styles */
.type-icon {
  width: 1.5rem;
  height: 1.5rem;
  display: inline-block;
  margin-right: 0.25rem;
}

/* Price analysis chart styles */
.price-chart {
  background-color: var(--chart-bg);
  border-radius: 0.5rem;
  padding: 1rem;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
}

/* Responsive design */
@media (max-width: 768px) {
  .grid {
    grid-template-columns: 1fr;
  }

  .card-list {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 480px) {
  .card-list {
    grid-template-columns: 1fr;
  }
}

/* Transitions */
.transition-colors {
  transition-property: background-color, border-color, color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.transition-transform {
  transition-property: transform;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

/* Animations */
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

.animate-fadeIn {
  animation: fadeIn 0.3s ease-in-out;
}

/* Utility classes */
.shadow-md {
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.rounded-xl {
  border-radius: 0.75rem;
}

.font-bold {
  font-weight: 700;
}

.text-center {
  text-align: center;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mt-8 {
  margin-top: 2rem;
}

.p-4 {
  padding: 1rem;
}

.flex {
  display: flex;
}

.items-center {
  align-items: center;
}

.justify-between {
  justify-content: space-between;
}

.w-full {
  width: 100%;
}

.h-full {
  height: 100%;
}

.overflow-hidden {
  overflow: hidden;
}

.cursor-pointer {
  cursor: pointer;
}


/* Hide Scrollbar */

.hide-scrollbar {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
}
.hide-scrollbar::-webkit-scrollbar { 
  width: 0;
  height: 0;
  display: none;  /* Safari and Chrome */
}